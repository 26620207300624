import React, { Component } from 'react';
import Lobby from './Lobby';
import TypeScreen from './TypeScreen';


import { CircularProgress, Box, Paper, Typography } from '@material-ui/core';

class MainPlay extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        console.log("RENDERING MAINPLAY");
        return(
            <Box height="100%">
            { this.props.start ? 
                <TypeScreen startTimer={this.props.startTimer} update={this.props.updateWords} finish={this.props.stopGame} excerpt={this.props.excerpt} /> : 
                <Lobby sendMessage={this.props.sendMessage} messages={this.props.messages} loading={this.props.loading} /> 
            }
            </Box>
        );
    }
}

export default MainPlay;
