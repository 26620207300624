import React, { Component } from 'react';
import { Fade, CircularProgress, Box, Grid, Container, TextField, Button, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { name: "", error: false, helperText: "", loading: true };
    this.nameChangeHandler = this.nameChangeHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({loading: false});
    }, 1500);
  }

  nameChangeHandler(e) {
    const name = e.target.value;
    this.setState({name: name});
    if (name.trim() !== "" && this.state.error === true) {
      this.setState({error: false, helperText: ""});
    }
  }

  submitHandler(e) {
    e.preventDefault();
    console.log(this.state.name);
    if (this.state.name.trim() === "") {
      this.setState({ error: true, helperText: "You must enter a name." });
    }
    else {
      this.props.play(this.state.name);
    }
    return false;
  }

  render() {
    return (
      <Box maxWidth="md" padding="10vh">
        <Paper elevation={3}>
          <Box display="flex" alignItems="center" justifyContent="center" minHeight="400px" height="60vh">
            {this.state.loading ?
            <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={90} />
            </Box> :
            <Fade in={!this.state.loading}>
            <form onSubmit={this.submitHandler}>
              <Grid spacing={8} container direction="column" alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h1">Type Away</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box width={300}>
                    <TextField fullWidth helperText={this.state.helperText} error={this.state.error} label="Name" variant="filled" required onChange={this.nameChangeHandler} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit">Continue</Button>
                </Grid>
              </Grid>
            </form> 
            </Fade> }
            </Box>
        </Paper>
      </Box>
    );
  }
}

export default Home;