import React, { Component } from 'react';
import { CircularProgress, Box, Paper, Typography, Button } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import {LinearProgress } from '@material-ui/core';

class Player extends Component {
    constructor(props) {
        super(props);
        this.props = props;

    }
  
    render() {
        return (
            <Box display="flex" flexDirection="column" alignItems="center">
                <Box alignItems="center" display="flex">
                    <Typography align="center" variant="body1">{this.props.place}. {this.props.player.name}</Typography> 
                    {this.props.player.gameLeader && <StarIcon />}
                    <Box component="span" marginLeft={1}>
                        <Typography align="center" variant="body1"> {`${this.props.player.speed} WPM`}</Typography>
                    </Box>
                </Box>
                <Box width="200px">
                    <LinearProgress variant="determinate" value={this.props.player.progress} />
                </Box>
            </Box>
        )
            
    }
    
  }
  export default Player;