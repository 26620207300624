import React, { Component } from 'react';
import { Typography, Box } from '@material-ui/core';

class TypeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      words: 0,
      excerptArr: props.excerpt.match(/\S+/g),
      totalWords: 0,
      count: 3
    }
    this.textInput = React.createRef();
    this.updateParent = this.updateParent.bind(this);
    this.disallowMultipleSpace = this.disallowMultipleSpace.bind(this);
    this.disallowCheating = this.disallowCheating.bind(this);
  }

  componentDidMount() {
      this.interval = setInterval(() => {
        this.setState({count: this.state.count-1});
        if (this.state.count === 0) {
          clearInterval(this.interval);
          this.props.startTimer();
          this.textInput.current.focus();
        }
      }, 1000);
  }

  updateParent(e) {
    let excerptWords = this.props.excerpt.match(/\S+/g);
    let str = e.target.innerHTML;
    const words = str.replace(/&nbsp;/, " ").match(/\S+/g);
    if (!!words){
      this.setState({totalWords: words.length});
      const correctWords = [...words];
        for (let i = words.length; i >= 0; i--) { 
            console.log("comparing ", correctWords[i], " to ", excerptWords[i]);
            let curr = correctWords[i];
            if (curr !== excerptWords[i]) {
                correctWords.splice(i, 1);
            }
        }
        this.setState({words: correctWords.length}, () => this.props.update(this.state.words));
        console.log("words: ", words.length, " excerpt: ", excerptWords.length);
        if (words.length > excerptWords.length || correctWords[correctWords.length-1] === excerptWords[excerptWords.length-1]) {
            this.props.finish();
        }
    }
    else {
        this.setState({words: 0});
    }
    console.log("excerpt: ", excerptWords);
    console.log(words);
  }

  disallowMultipleSpace(e) {
    if (e.which === 32 && e.target.innerHTML.endsWith("nbsp;")) {
        e.preventDefault();
        return false;
    }
  }

  disallowCheating(e) {
    e.preventDefault();
    return false;
  }

  render() {
    return (
      <Box height="100%" className="Background" p={3}>
        {this.state.count > 0 ? 
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h2">{this.state.count}</Typography>
        </Box> :
        <Typography variant="body1">
          <Box component="span" color="primary.main">{this.state.excerptArr.slice(0,this.state.totalWords).join(" ")}</Box> <Box onCopy={this.disallowCheating} component="span" color="secondary.main">{this.state.excerptArr.slice(this.state.totalWords,this.state.excerptArr.length).join(" ")}</Box>
          <Box spellCheck="false" onPaste={this.disallowCheating} p={1} minHeight={20} ref={this.textInput} onKeyDown={this.disallowMultipleSpace} onKeyUp={this.updateParent} className="typebox" contentEditable="true" style={{border:'1px solid black'}}></Box>
        </Typography>
        }
      </Box>
    );
  }
  
}
export default TypeScreen;