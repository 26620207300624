import React from 'react';
import MenuBack from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import {Button, AppBar, Toolbar, IconButton, Typography, Link } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));

const goBack = () => {
  window.location.href = "http://justinyh.com";
}

const preventDefault = (event) => event.preventDefault();
export default function NavBar() {
    const classes = useStyles();
    return (
        <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="back" onClick={goBack}>
            <MenuBack />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Type Away
          </Typography>
        </Toolbar>
      </AppBar>
    )
}