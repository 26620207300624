function Timer() {
	this.time = 0;
	this.callback = null;
  }
  
Timer.prototype.start = function start() {
	this.interval = setInterval(() => {
		this.time += 1;
		if (!!this.callback) {
			this.callback(this.time);
		}
	}, 1000);
};

Timer.prototype.stop = function stop() {
	clearInterval(this.interval);
};

Timer.prototype.reset = function reset() {
	this.time = 0;
};

Timer.prototype.onTick = function onTick(callback) {
	this.callback = callback;
};

export default Timer;