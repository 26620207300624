import React, { Component } from 'react';

import { CircularProgress, Box, Paper, Typography, Button } from '@material-ui/core';

class ActionBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showStart: false,
        }
    }

    render() {
        return(
            <Box p={2}>
            {this.props.showStart && <Button variant="contained" color="primary" onClick={this.props.startClick}>Start Game</Button>}
            {this.props.waitForStart && <Typography align="center" variant="body1">Please wait for the game leader to start the game.</Typography>}
            {this.props.waitForFinish && <Typography align="center" variant="body1">Please wait for the other players to finish.</Typography>}
            </Box>
        )
    }
}

export default ActionBox;
