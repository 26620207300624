import React from 'react';
import MenuBack from '@material-ui/icons/ArrowBack';
import { Paper, Button, Box, Typography } from '@material-ui/core';

export default function ErrorMessage(props) {
    let message = "";
    switch(props.type) {
        case "server":
            message = "There was an error connecting to the server. Try again later.";
            break;
        default:
            message = "There was an error. Please try again later.";
    }
    return (
        <Box height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant="body1">{message}</Typography>
            <div>
                <Button size="medium" variant="contained" startIcon={<MenuBack />}color="primary" aria-label="menu" onClick={props.back}>Back to Home</Button>
            </div>
        </Box>
    );
}