import React, { Component } from 'react';

import { CircularProgress, Box, TextField, Typography, Paper } from '@material-ui/core';
import Message from './Message';

class Lobby extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
        }
        this.updateHandler = this.updateHandler.bind(this);
        this.send = this.send.bind(this);
    }

    send(e) {
        e.preventDefault();
        this.props.sendMessage(this.state.message);
        this.setState({message: ""});
    }

    updateHandler(e) {
        const message = e.target.value;
        this.setState({message: message});
    }

    render() {
        return(
            <Box height="100%">
            { this.props.loading ? <Box height="100%" display="flex" justifyContent="center" alignItems="center"><CircularProgress size={90} /></Box> :
                <Box>
                <Box display="flex" flexDirection="column" height="500px" overflow="auto" >
        {this.props.messages.map((message, index) => <Message ref={(el) => { this.messagesEnd = el; }} key={index} message={message} /> )}
                </Box>
            <Box marginTop={1}>
                <form onSubmit={this.send}>
                    <TextField fullWidth variant="outlined" label="Chat" value={this.state.message} onChange={this.updateHandler} />
                </form>
            </Box>
            </Box>
            }
            </Box>
        );
    }
}

export default Lobby;
