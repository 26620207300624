import React, { Component } from 'react';
import Home from './Home';
import GamePage from './GamePage';
import { Button, Box, ThemeProvider, createMuiTheme } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import { palette } from '@material-ui/system';
import NavBar from './NavBar';
import '../assets/app.scss';
/*
const theme = createMuiTheme({
  palette: {
    primary: {
      main: red[500],
    },
    secondary: {
      main: green[500],
    },
  },
});
*/
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { play: false}
    this.play = this.play.bind(this);
    this.back = this.back.bind(this);
  }

  play(name) {
    this.name = name;
    console.log("Let's play,", name);
    this.setState({ play: true});
  }

  back() {
    this.setState({ play: false });
  }

  render() {
    return (
      <Box height="100%" bgcolor="primary.dark">
      <NavBar />
        { this.state.play ?
          <GamePage name={this.name} back={this.back} /> :
          <Home play={this.play} />
        }
      </Box>
    );
  }
  
}
export default App;
