import React, { Component } from 'react';
import PlayerStatus from './PlayerStatus';
import ActionBox from './ActionBox';
import io from 'socket.io-client';
import Timer from './Timer';
import MainPlay from './MainPlay';
import ErrorMessage from './ErrorMessage';
import StatsBox from './StatsBox';
import { Container, Box, ThemeProvider, createMuiTheme, Slide, Paper, Grid } from '@material-ui/core';

const connection = "https://type-away-justin.herokuapp.com/";
class GamePage extends Component {
  constructor(props) {
    super(props);
    this.name = '';
    this.timer = new Timer;
    this.socket = null;
    this.state = {
        playerFinished: true,
        gameFinished: true,
        time: 0,
        words: 0,
        excerpt: "",
        error: false,
        loading: true,
        players: [],
        self: {},
        messages: []
    };
    this.listenToMessages = this.listenToMessages.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.startClick = this.startClick.bind(this);
    this.updateWords = this.updateWords.bind(this);
    this.stopGame = this.stopGame.bind(this);
    this.errorOut = this.errorOut.bind(this);
    this.toggleSpectator = this.toggleSpectator.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.sendUpdates = this.sendUpdates.bind(this);
  }

  errorOut() {
    this.setState({error: true, loading: false});
    this.socket.close();

  }

  listenToMessages() {
    const options = {
      timeout: 5000,
    }
    this.socket = io(connection, options);
    this.socket.emit('join', this.props.name);
    this.socket.on('connect', () => {
      console.log("Connected!");
      this.setState({loading: false});
    });
    this.socket.on('connect_error', () => {
      console.log("Sorry, there seems to be an issue with the connection!");
      this.errorOut();
    });
    this.socket.on('playerchange', (details) => {
      const detailObj = JSON.parse(details);
      let selfObj = detailObj.players.find((element) => element.id === this.socket.id);
      if (!selfObj) {
        selfObj = detailObj.spectators.find((element) => element.id === this.socket.id);
      }
      console.log("self", selfObj);
      this.setState({
        players: detailObj.players, 
        self: selfObj, 
      });
    });
    this.socket.on('gamestarted', (details) => {
      const detailObj = JSON.parse(details);
      let selfObj = detailObj.players.find((element) => element.id === this.socket.id);
      if (!selfObj) {
        selfObj = detailObj.spectators.find((element) => element.id === this.socket.id);
      }
      this.setState({ excerpt: detailObj.excerpt, players: detailObj.players, playerFinished: false, gameFinished: false, self: selfObj});
    });
    this.socket.on('gameupdate', (details) => {
      const detailObj = JSON.parse(details);
      let selfObj = detailObj.players.find((element) => element.id === this.socket.id);
      this.setState({players: detailObj.players, self: selfObj});
    });
    this.socket.on('gamefinished', (gameLeader) => {
      this.setState({gameFinished: true, playerFinished: true});
    });
    this.socket.on('message', (message) => {
      console.log("message received: ", message);
      const arr = this.state.messages.concat(message);
      this.setState({messages: arr});
    })
  }
  
  componentDidMount() {
    this.listenToMessages();
    this.timer.onTick((time) => { this.setState({ time: time})});
  }

  sendUpdates() {
    this.interval = setInterval(() => {
      this.socket.emit('update', {time: this.state.time, words: this.state.words});
    }, 500);
  }
  
  startClick() {
    this.socket.emit('startgame');
  }

  startTimer() {
    this.timer.start(); 
    this.sendUpdates();
  }


  stopGame() {
    this.setState({playerFinished: true, time: 0});
    this.timer.stop();
    clearInterval(this.interval);
    this.socket.emit('playerfinish', {words: this.state.words, time: this.state.time});
    this.timer.reset();
    this.setState({time: 0});
  }

  toggleSpectator() {
    this.socket.emit('togglespectator');
  }

  updateWords(words) {
    this.setState({words: words});  
  }
  sendMessage(message) {
    this.socket.emit('message', {
      content: message,
      time: Date.now()
    });
  }

  render() {
    return (
      <Container>
        <Box marginTop="50px">
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Paper>
              <Box p={2} height="564px">
                {this.state.error ? <ErrorMessage type="server" back={this.props.back} /> :
                <MainPlay startTimer={this.startTimer} sendMessage={this.sendMessage} messages={this.state.messages} start={!this.state.playerFinished && !this.state.self.spectator} loading={this.state.loading} updateWords={this.updateWords} excerpt={this.state.excerpt} stopGame={this.stopGame} /> }
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <PlayerStatus gameFinished={this.state.gameFinished} toggleSpectator={this.toggleSpectator} self={this.state.self} players={this.state.players} spectators={this.state.spectators}/>
            </Box>
            <Box marginTop="16px">
              <Paper>
                <Box height="110px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  {!this.state.playerFinished ? 
                  <StatsBox self={this.state.self} /> :
                  <ActionBox showStart={this.state.self.gameLeader && this.state.gameFinished} waitForStart={!this.state.self.gameLeader && this.state.gameFinished} waitForFinish={this.state.playerFinished && !this.state.gameFinished} startClick={this.startClick} /> }
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
        </Box>
      </Container>
    );
  }
  
}
export default GamePage;