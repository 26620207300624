import React, { Component } from 'react';

import { CircularProgress, Box, Paper, Typography, Button } from '@material-ui/core';

class StatsBox extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return(
            <Box>
                {this.props.self.spectator ? 
                <Typography variant="body1">You will join the game next round.</Typography> 
                :
                <Box>
                    <Typography variant="body1">Time: {this.props.self.time}</Typography>
                    <Typography variant="body1">Correct Words: {this.props.self.words}</Typography>
                    <Typography variant="body1">WPM: {this.props.self.speed}</Typography>
                </Box>
                }
            </Box>
        )
    }
}

export default StatsBox;