import React, { Component } from 'react';

import { CircularProgress, Box, TextField, Typography, Paper } from '@material-ui/core';

class Message extends Component {
    constructor(props) {
        super(props);
        this.message = React.createRef();
    }
    
    componentDidMount() {
        this.message.current.scrollIntoView({ behavior: "smooth" });
    }

    render() {
        return(
            <Box ref={this.message} border="1px solid black" m={1} p={1}>
                <Typography variant="subtitle1">{this.props.message.name}</Typography>
                <Typography variant="body2">{this.props.message.content}</Typography>
                <Typography variant="body2">{new Date(this.props.message.time).toLocaleTimeString()}</Typography>
            </Box>
        );      
    }
}

export default Message;