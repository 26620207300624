import React, { Component } from 'react';
import { CircularProgress, Box, Paper, Typography, Button } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import Player from './Player';

class PlayerStatus extends Component {
    constructor(props) {
      super(props);
      this.props = props;

    }
  
    render() {
      return (
        <Paper>
            <Box height="470px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant="h6">PLAYERS</Typography>
            {this.props.players.filter((player) => player.spectator === false).sort((a, b) => b.speed - a.speed).map((player, index) => <Player place={index+1} player={player} key={player.id}/>)}
            {this.props.self && this.props.self.spectator && this.props.gameFinished ? <Button onClick={this.props.toggleSpectator} variant="contained" color="secondary">Join as player</Button> : ""}
            <Typography variant="h6">SPECTATORS</Typography>
            {this.props.players.filter((player) => player.spectator === true).map((spectator) => <Typography variant="body1" key={spectator.id}>{spectator.name}</Typography>)}
            {this.props.self && !this.props.self.spectator && this.props.gameFinished ? <Button onClick={this.props.toggleSpectator} variant="contained" color="secondary">Join as spectator</Button> : ""}
        </Box>
        </Paper>
      );
    }
    
  }
  export default PlayerStatus;